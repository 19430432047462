















































































import Vue from 'vue'
import { FormDefinition } from '@/types/formDefinition'
import schindlerApi from '@/services/schindlerApi'
import { extend, localize } from 'vee-validate'
import { required, min, regex } from 'vee-validate/dist/rules'
import { snackbarActions } from '@/store/modules/Snackbar/actions'

//TODO will need perhaps refactor
extend('required', {
	...required,
})

extend('passwordConfirm', {
	params: ['target'],
	//@ts-ignore
	validate(value, { target }) {
		return target === value
	},
	message: 'Passwords does not match',
})

extend('min', {
	...min,
})

extend('oneNumber', {
	validate(value: string) {
		return value.match(/[0-9]/g) !== null
	},
	message: '{_field_} must contain at least one number',
})

extend('upCase', {
	validate(value: string) {
		return value.match(/[A-Z]/g) !== null
	},
	message: '{_field_} must contain at least one upper case letter',
})

extend('lowCase', {
	validate(value: string) {
		return value.match(/[a-z]/g) !== null
	},
	message: '{_field_} must contain at least one lower case letter',
})

//TODO move to translation file
//TODO need to localize messages from backend
localize({
	en: {
		fields: {
			old_password: {
				required: 'Please fill your old password',
			},
			new_password: {
				required: 'Please fill your new password',
				min: 'Password must be at leas {length} characters long',
				regex: 'Password must contain at least one upper case letter and lowe case letter and number',
				lowCase: 'Password must contain at least one lower case letter',
				upCase: 'Password must contain at least one upper case letter',
				oneNumber: 'Password must contain at least one number',
			},
			repeat_password: {
				required: 'Please repeat your password',
			},
		},
	},
})

interface IForm extends FormDefinition {
	formValues: {
		oldPassword: string | null
		newPassword: string | null
		repeatPassword: string | null
	}
}

export default Vue.extend({
	name: 'ChangePasswordForm',
	props: {
		error: {
			type: Array,
		},
	},
	data() {
		return {
			showOldPassword: false,
			showNewPassword: false,
			showRepeatPassword: false,
			loading: false,
			form: {
				valid: false,
				formValues: {
					oldPassword: null,
					newPassword: null,
					repeatPassword: null,
				},
			} as IForm,
		}
	},
	methods: {
		async submit() {
			if ((this.$refs.observer as Vue & { validate: () => boolean }).validate()) {
				await schindlerApi
					.put('/auth/users/me/password', {
						old_password: this.form.formValues.oldPassword,
						new_password: this.form.formValues.newPassword,
					})
					.then(() => {
						//success message
						this.$store.dispatch('snackbar/' + snackbarActions.ADD_SNACKBAR, {
							active: true,
							message: 'Password has been changed',
							color: 'success',
						})
						//flush form
						this.form.formValues.oldPassword = null
						this.form.formValues.newPassword = null
						this.form.formValues.repeatPassword = null
						//flush validation
						;(this.$refs.observer as Vue & { reset: () => void }).reset()
					})
					.catch((error) => {
						//422 - check location
						//400 - bad password
						if (error.response.status === 422) {
							;(this.$refs.observer as Vue & { setErrors: (errors: any) => void }).setErrors({
								[error.response.data.detail[0].loc[1]]: [error.response.data.detail[0].msg],
							})
						} else if (error.response.status === 400) {
							this.$store.dispatch('snackbar/' + snackbarActions.ADD_SNACKBAR, {
								active: true,
								message: error.response.data.detail,
								color: 'error',
							})
						} else {
							//throw generic error
							this.$store.dispatch('snackbar/' + snackbarActions.ADD_SNACKBAR, {
								active: true,
								message: 'Internal server error',
								color: 'error',
							})
						}
					})
			}
		},
	},
})
