
















import Vue from 'vue'
import ChangePasswordForm from '@/components/Forms/ChangePasswordForm.vue'

export default Vue.extend({
	name: 'Profile',
	components: { ChangePasswordForm },
})
