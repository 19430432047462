var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var failed = ref.failed;
return [_c('v-form',{attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.submit())}},model:{value:(_vm.form.valid),callback:function ($$v) {_vm.$set(_vm.form, "valid", $$v)},expression:"form.valid"}},[_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"old_password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.form.formValues.oldPassword,"error-messages":errors,"label":"Old password","type":_vm.showOldPassword ? 'text' : 'password'},on:{"input":function($event){_vm.form.formValues.oldPassword = $event !== '' ? $event : null},"blur":function($event){_vm.showOldPassword = false}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"tabindex":"-1","small":"","text":"","fab":""},on:{"click":function($event){_vm.showOldPassword = !_vm.showOldPassword}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(("" + (_vm.showOldPassword ? 'fa-eye' : 'fa-eye-slash'))))])],1)]},proxy:true}],null,true)})]}}],null,true)})],1),_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"new_password","rules":"required|min:8|lowCase|upCase|oneNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.form.formValues.newPassword,"error-messages":errors,"label":"New password","type":_vm.showNewPassword ? 'text' : 'password'},on:{"input":function($event){_vm.form.formValues.newPassword = $event !== '' ? $event : null},"blur":function($event){_vm.showNewPassword = false}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"tabindex":"-1","small":"","text":"","fab":""},on:{"click":function($event){_vm.showNewPassword = !_vm.showNewPassword}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(("" + (_vm.showNewPassword ? 'fa-eye' : 'fa-eye-slash'))))])],1)]},proxy:true}],null,true)})]}}],null,true)})],1),_c('v-col',{staticClass:"py-2",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"repeat_password","rules":"required|passwordConfirm:@new_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.form.formValues.repeatPassword,"error-messages":errors,"label":"Repeat password","type":_vm.showRepeatPassword ? 'text' : 'password'},on:{"input":function($event){_vm.form.formValues.repeatPassword = $event !== '' ? $event : null},"blur":function($event){_vm.showRepeatPassword = false}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"tabindex":"-1","small":"","text":"","fab":""},on:{"click":function($event){_vm.showRepeatPassword = !_vm.showRepeatPassword}}},[_c('v-icon',{attrs:{"tabindex":"-1","small":""}},[_vm._v(_vm._s(("" + (_vm.showRepeatPassword ? 'fa-eye' : 'fa-eye-slash'))))])],1)]},proxy:true}],null,true)})]}}],null,true)})],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"my-2 white--text",attrs:{"color":"primary","elevation":"4","type":"submit","loading":_vm.loading,"disabled":_vm.loading || failed}},[_vm._v(" Submit ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }